import React, { useState, useEffect } from "react";
import "./App.css";

// Function to generate a random number between a range
const getRandomNumber = (min, max) => Math.random() * (max - min) + min;

const FloatingHearts = () => {
  useEffect(() => {
    // Function to create new hearts
    const createHearts = () => {
      const numHearts = Math.floor(getRandomNumber(10, 30)); // Random number of hearts between 5 and 20

      for (let i = 0; i < numHearts; i++) {
        const heart = document.createElement("span");
        heart.className = "heart";
        heart.textContent = "❤️"; // The heart emoji
        heart.style.left = `${getRandomNumber(0, 100)}%`; // Random horizontal position
        heart.style.animationDelay = `${getRandomNumber(0, 2)}s`; // Random delay between 0s to 2s
        heart.style.fontSize = `${getRandomNumber(20, 40)}px`; // Random heart size between 20px and 40px

        // Append the heart to the body (or container element)
        document.body.appendChild(heart);

        // Remove heart after animation ends (4s)
        setTimeout(() => {
          heart.remove();
        }, 4000); // Remove heart after 4s (duration of animation)
      }
    };

    // Generate hearts every 1 second
    const interval = setInterval(createHearts, 1000);

    // Clean up when component unmounts
    return () => clearInterval(interval);
  }, []);

  return null; // No need to render anything here, hearts are dynamically created in the DOM
};

const Countdown = ({ targetDate }) => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(targetDate));

  function calculateTimeRemaining(target) {
    const now = new Date();
    const gmt8Time = new Date(
      now.toLocaleString("en-US", { timeZone: "Asia/Singapore" })
    ).getTime();
    const targetTime = new Date(target).getTime();
    const difference = targetTime - gmt8Time;

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(targetDate));
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <div className="countdown-container">
      <h1 className="countdown-title">Countdown to Anis's Birthday! 🎊🥳🎀</h1>
      <div className="countdown-timer">
        <div className="countdown-box">
          <span>{timeRemaining.days}</span>
          <p>Days</p>
        </div>
        <div className="countdown-box">
          <span>{timeRemaining.hours}</span>
          <p>Hours</p>
        </div>
        <div className="countdown-box">
          <span>{timeRemaining.minutes}</span>
          <p>Minutes</p>
        </div>
        <div className="countdown-box">
          <span>{timeRemaining.seconds}</span>
          <p>Seconds</p>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <div className="app">
      <Countdown targetDate="2024-12-16T23:59:59+08:00" />
      <FloatingHearts /> {/* Add floating hearts component */}
    </div>
  );
};

export default App;
